import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";

import useAuthHelper from "../../services/useAuthHelper";
import useAuthService from "../../services/useAuthService";
import useApiHelper from "../../services/useApiHelper";
import useUserContext, { UserContextActions } from "../../contexts/UserContext";
import "./signin.css";
import FullscreenLoading from "../../components/Shared/FullscreenLoading";
import ResetPasswordModal from "../../components/ResetPassword/ResetPasswordModal";

import EstateTrackLogo from "../../assests/EstateTrackLogo.png";

function LogoutMessage({ setShowLogoutMessage }) {
  return (
    <div className="-mt-4 flex flex-col items-center">
      <CheckCircleIcon className="w-36 h-36 text-green-600" />
      <h2 className="mt-4 text-3xl text-center font-bold text-gray-900">
        You've been logged out
      </h2>
      <button
        onClick={() => setShowLogoutMessage(false)}
        type="button"
        className="mt-8 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        Sign In Again
      </button>
    </div>
  );
}

function SignInError({ error }) {
  return (
    <div className="rounded-md bg-red-50 mt-6 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-800">{error}</p>
        </div>
      </div>
    </div>
  );
}

export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const { get } = useApiHelper();
  const { post } = useAuthHelper();
  const { getUser, setTokenRefresh, refreshToken, isTokenValid } =
    useAuthService();
  const { dispatch } = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authenticating, setAuthenticating] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const [showForgotPassword, setShowForgotPassword ] = useState(false);

  useEffect(() => {
    document.title = "EstateTrack Portal | Login";
    setLoading(true);
    let user = getUser();
    if (!user) {
      //No user stored, show login form
      dispatch({ type: UserContextActions.loaded });
      setLoading(false);
    } else {
      //Check token has not expired
      if (isTokenValid(user.identity?.jwtTokenExpiration)) {
        dispatch({
          type: UserContextActions.setIdentity,
          payload: user.identity,
        });
        setTokenRefresh(user.identity);
        getUserProfile(user);
      } else {
        //Attempt to refresh token
        let tokenResult = refreshToken();
        if (tokenResult) {
          //Refreshed token, get user profile and redirect
          getUserProfile({ identity: tokenResult });
        } else {
          //Token unable to be refreshed display login
          dispatch({ type: UserContextActions.loaded });
          setLoading(false);
        }
      }
    }
  }, []);

  const handleGoogleAuthCheck = (identity) => {
    get("/integration/googlestatus").then((res) => {
      if (!res.success) {
        dispatch({ type: UserContextActions.setGoogleAuthed, payload: false });  
      }
      // success or failure, continue as normal
      dispatch({ type: UserContextActions.loaded });
      handleAuthedRedirect(identity);
    });
  };
    

  const handleAuthedRedirect = (identity, agency) => {
    console.log(location);
    if (location.state && location.state.attemptedRoute) {
      if (location.state.attemptedRoute === "/" && identity.role === "Admin") {
        delete location.state.attemptedRoute;
        navigate("/admin");
      } else {
        navigate(location.state.attemptedRoute);
        delete location.state.attemptedRoute;
      }
    } else if (identity.role === "Admin") {
      navigate("/admin");
    } else {
      navigate(`/${agency}`);
    }
  };

  const getUserProfile = (user) => {
    get("/user/me").then((userRes) => {
      let agencyList = userRes.data.agencyAccess;
      dispatch({ type: UserContextActions.setAgencies, payload: agencyList });

      delete userRes.data.agencyAccess;
      dispatch({ type: UserContextActions.setUser, payload: userRes.data });

      console.log("User", user);
      if (user.agency) {
        dispatch({
          type: UserContextActions.setAgency,
          payload: user.agency,
        });
        dispatch({ type: UserContextActions.loaded });
        handleAuthedRedirect(user, user.agency.logicalName);
      } else {
        if (user.identity.role !== "Admin") {
          let agencyToLoad = agencyList.find(
            (a) => a.id === userRes.data.defaultAgency
          );
          dispatch({
            type: UserContextActions.setAgency,
            payload: agencyToLoad,
          });
          dispatch({ type: UserContextActions.loaded });
          handleAuthedRedirect(user, agencyToLoad.logicalName);
        } else {
          // Check google status here
          handleGoogleAuthCheck(user.identity);
          // dispatch({ type: UserContextActions.loaded });
          // handleAuthedRedirect(user.identity);
        }
      }
    });
  };

  const handleLogin = (event) => {
    event.preventDefault();

    setAuthenticating(true);

    error !== "" && setError("");

    post("/auth/authenticate", { email: email, password: password })
      .then((authRes) => {
        dispatch({ type: UserContextActions.setIdentity, payload: authRes });
        getUserProfile({ identity: authRes });
      })
      .catch((err) => {
        console.error(err);
        setError(err.Message);
        setAuthenticating(false);
      });
  };

  if (loading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
      <ResetPasswordModal open={showForgotPassword} setOpen={setShowForgotPassword} />
      <div className="w-screen block md:flex">
        <div className="signinImage flex-1"></div>
        <div className="w-full h-screen sm:2-3/4 md:w-2/4 lg:w-2/5 2xl:w-1/4 py-4 flex justify-center bg-white overflow-y-auto">
          <div className="flex w-11/12 lg:w-10/12">
            <div className="flex flex-col justify-center my-8 w-full">
              <img
                className="mb-10 h-10 mx-auto w-auto"
                src={EstateTrackLogo}
                alt="Workflow"
              />
              {loading ? (
                <div className="flex justify-center">
                  <LoadingWheel width="w-36" height="w-36" />
                </div>
              ) : showLogoutMessage ? (
                <LogoutMessage setShowLogoutMessage={setShowLogoutMessage} />
              ) : (
                <>
                  <h2 className="text-3xl font-bold text-gray-900">Sign in</h2>
                  {error !== "" && <SignInError error={error} />}
                  <div className="mt-6">
                    <form onSubmit={handleLogin} className="space-y-6">
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={authenticating}
                            autoComplete="email"
                            required
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${authenticating && "bg-gray-100"
                              }`}
                          />
                        </div>
                      </div>

                      <div className="space-y-1">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            disabled={authenticating}
                            autoComplete="current-password"
                            required
                            className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${authenticating && "bg-gray-100"
                              }`}
                          />
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            className="h-4 w-4 text-etpink-600 focus:ring-etpink-500 border-gray-300 rounded"
                          />
                          <label
                            htmlFor="remember-me"
                            className="ml-2 block text-sm text-gray-900"
                          >
                            Remember me
                          </label>
                        </div>

                        <div className="text-sm">
                          <button
                            type="button"
                            onClick={() => setShowForgotPassword(true)}
                            className="font-medium text-etpink-600 hover:text-etpink-500"
                          >
                            Forgot your password?
                          </button>
                        </div>
                      </div>

                      <div>
                        <button
                          disabled={authenticating}
                          type="submit"
                          className={`${authenticating &&
                            "bg-gray-100 hover:pointer-events-none hover:bg-gray-100"
                            } w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500`}
                        >
                          {authenticating ? (
                            <LoadingWheel width="w-6" height="h-6" />
                          ) : (
                            "Sign In"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      </>
    );
  }
}
