import classNames from "../../../utils/classNames"

export const ButtonLink = ({ label, href, classes = "", target = "_blank", children }) => {

  return (
    <a
      className={classNames(
        "text-center w-full md:w-auto items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500",
        classes
      )}
      href={href}
      target={target}
      type="button"
    >
      {label || children}
    </a>
  )
}