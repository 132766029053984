import { useState } from "react";

import useAiWriter from "../../data/useAiWriter";
import { AiButtonInput } from "../Shared/Inputs/AiButtonInput";
import { ButtonInput } from "../Shared/Inputs/ButtonInput";
import LoadingWheel from "../Shared/LoadingWheel";
import Modal from './../Shared/Modal';

export default function ReplyToReviewModal({ agencyId, handleSubmit, open, setOpen, reviewText }) {
  const { review } = useAiWriter();
  const [reply, setReply] = useState('');
  const [replyLoading, setReplyLoading] = useState(false);

  const getAiReply = (e) => {
    e.stopPropagation();

    setReplyLoading(true);

    const body = {
      reviewText: reviewText
    };

    review.mutate(body, {
      onSuccess: (res) => {
        if (res?.data)
          setReply(res.data);
      },
      onSettled: () => {
        setReplyLoading(false);
      }
    });
  };

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-6xl"}>
      <div className="space-y-4 p-4">
        <div>
          <textarea className="w-full h-64 border border-gray-300 rounded-md p-2" value={reply} onChange={(e) => setReply(e.target.value)}></textarea>
        </div>

        <div className="flex justify-between">
          {replyLoading ? (
            <div className="pl-12">
              <LoadingWheel width="w-8" height="h-8" />
            </div>
          ) : (
            <AiButtonInput label={"Reply With AI"} isSubmit={false} agencyId={agencyId} onClick={getAiReply} />
          )}


          <ButtonInput label={"Submit"} isSubmit={false} onClick={() => handleSubmit(reply)} className="" />
        </div>

      </div>
    </Modal>
  );
};