import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import InsertMediaModal from '../MediaManager/InsertMediaModal';

import { CDN_URL } from "../../services/config";


const TinyMCE = ({ value, onChange, height, toolbar2 = true }) => {
  const editor = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentEditor, setCurrentEditor] = useState(null);

  const handleMediaInsert = (sourceType, mediaType, selectedMedia) => {
    let src;

    switch (sourceType) {
      case "URL":
        src = selectedMedia;
        break;
      case "Upload":
      case "Media Manager":
      default:
        src = `${CDN_URL}${selectedMedia.path}`;
        break;
    }

    if (mediaType === "Image") {
      currentEditor.insertContent(`<img src="${src}" alt=""/>`);
      setOpen(false);
    } else if (mediaType === "Video") {
      currentEditor.insertContent(`<video controls><source src={${src}} type={${selectedMedia.type}}/></video>`);
      setOpen(false);
    }

  }


  return (
    <>
      <InsertMediaModal
        open={open}
        setOpen={setOpen}
        onInsert={handleMediaInsert}
      />
      <Editor
        ref={editor}
        initialValue={value}
        // link to tinymce folder in public dir
        tinymceScriptSrc='/tinymce/tinymce.min.js'
        licenseKey='gpl'
        // apiKey='1ra4zdqd31674fayamej7cl87iv18mzlmrmols25smhpjjnl'
        init={{
          height: height,
          menubar: false,
          // plugins: 'link lists advlist autolink autosave charmap code emoticons fullscreen hr image imagetools insertdatetime nonbreaking pagebreak paste preview print save searchreplace table template textcolor visualblocks wordcount textpattern',
          plugins: 'link lists advlist autolink autosave charmap code emoticons fullscreen image insertdatetime nonbreaking pagebreak preview save searchreplace table visualblocks wordcount',
          toolbar1: 'undo redo | bold italic underline strikethrough | fontfamily fontsize | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
          toolbar2: toolbar2 ? 'link | forecolor backcolor | removeformat | customButton' : "",
          // plugins: 'link lists advlist autolink autosave charmap code emoticons fullscreen hr image imagetools insertdatetime media nonbreaking pagebreak paste preview print save searchreplace table template textcolor visualblocks wordcount',
          setup: (editor) => {
            editor.ui.registry.addButton('customButton', {
              icon: 'image',
              tooltip: 'Media Manager',
              onAction: () => {
                setCurrentEditor(editor);
                setOpen(true);
              }
            });
          },
          font_formats: 'Arial=arial,helvetica,sans-serif; Courier New=courier new,courier,monospace; Georgia=georgia,palatino; Tahoma=tahoma,arial,helvetica,sans-serif; Times New Roman=times new roman,times; Verdana=verdana,geneva;',
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',       
        }}
        onBlur={(e) => onChange(e.target.getContent())}
        onEdiorChange={(e) => alert(e.target.getContent())}
      />
    </>
  );
}

export default TinyMCE;