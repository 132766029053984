import { useState } from "react";

import { userRoleEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import ConfirmModal from "../Shared/ConfirmModal";

export default function GoogleReAuthModal() {
    const {
        user: { user, identity, googleAuthed },
        dispatch,
    } = useUserContext();

    const emailWhiteList = [
        "stephen@estatetrack.co.uk",
        "daniel@estatetrack.co.uk",
        "josh@simplecrumb.com",
        "ben@simplecrumb.com",
        "liam.wakely@simplecrumb.com"
    ];

    const [open, setOpen] = useState(googleAuthed);
    const agencyId = "14417684-7695-41F9-F217-08DBB83D7FAD";

    const onConfirm = () => {
        const clientId = "156965414692-buja5v0dvo4t6i99i1up8u9n4a1gq1m6.apps.googleusercontent.com"; 
        const redirectUri = "https://estatetrack-dashboard-api-live.azurewebsites.net/public/oauth/callback"; 
        // const redirectUri = "https://localhost:7259/public/oauth/callback"; 

        // Include the new Google My Business scope
        const scope = [
            "https://www.googleapis.com/auth/userinfo.email",
            "https://www.googleapis.com/auth/userinfo.profile",
            "https://www.googleapis.com/auth/business.manage"
        ].join(" "); // Join scopes with space for the OAuth URL

        const responseType = "code"; // Request an authorization code
        const state = encodeURIComponent(
            `agencyId=${agencyId},source=google,location=${window.location.href}`
        );

        // Add &access_type=offline and &prompt=consent
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth
            ?client_id=${clientId}
            &redirect_uri=${encodeURIComponent(redirectUri)}
            &scope=${encodeURIComponent(scope)}
            &response_type=${responseType}
            &state=${state}
            &access_type=offline
            &prompt=consent`.replace(/\s+/g, "");

        // Redirect user to Google's OAuth screen
        window.location.href = googleAuthUrl;
    }

    // Only for whitelisted admins
    if (identity == null) return (<></>);

    if (identity.role !== userRoleEnum.admin) return (<></>);

    if (user == null) return (<></>);

    if (!emailWhiteList.includes(user.emailAddress)) return (<></>);

    if (googleAuthed) return (<></>);

    return (
        <ConfirmModal title="Google Re-Authentication Required"
            message="Your Google session has expired. Please re-authenticate to continue."
            confirmButtonText="Re-Authenticate"
            open={open}
            setOpen={setOpen}
            onConfirm={onConfirm}
        />
    )
}