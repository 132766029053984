import React, { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";

import useReviews from "../../data/useReviews";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ActionButtonContainer from "../Shared/ActionButtonContainer";
import ActionTextButton from "../Shared/ActionTextButton";


export default function ViewRequestsModal({ open, setOpen }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Review Request");
  const { reviews, requests, resendRequest: resend } = useReviews();
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (date) => new Date(date).toLocaleString([], { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })
  const resendRequest = (request) => resend.mutate(request.id, saveHandlers);

  useEffect(() => {
    if (requests.data && reviews.data) {
      let filter = requests.data;

      //filter out requests that match current reviews - name
      filter = filter.filter((request) =>         
        !reviews.data.some(review => review.name === request.name)
      );

      setFilteredRequests(filter);
      setIsLoading(false);
    }
  }, [requests.data, reviews.data]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-5xl sm:w-full sm:p-6">

                <h1 className="text-xl font-semibold text-gray-900">Review Requests</h1>
                <p className="mt-2 mb-6 text-sm text-gray-700">
                  View and resend unviewed review requests.
                </p>

                {isLoading ? <p>Loading...</p>
                  : (
                    <div className="h-96 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
                      <table className="w-full">
                        <thead className="sticky top-0 bg-gray-50 z-10">
                          <tr>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Name</th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Email</th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Send Date</th>
                            <th className="px-4 py-2 text-left text-sm font-semibold text-gray-900">Resend Date</th>
                            <th className="relative py-3.5 pl-3 pr-4 sm:pr-6"><span class="sr-only">Actions</span></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {filteredRequests.map((request, index) => (
                            <tr
                              key={index}
                              className="hover:bg-gray-100 hover:cursor-pointer"
                              onClick={() => { }}
                            >
                              <td className="px-4 py-2">{request.name}</td>
                              <td className="px-4 py-2">{request.email}</td>
                              <td className="px-4 py-2">{request.sendDate ? formatDate(request.sendDate) : request.sendDate}</td>
                              <td className="px-4 py-2">{request.resendDate ? formatDate(request.resendDate) : request.resendDate}</td>
                              <td className="px-4 py-2">
                                <ActionButtonContainer>
                                  <ActionTextButton onClick={(e) => {
                                    e.stopPropagation();
                                    resendRequest(request);
                                  }}
                                    text="Resend"
                                  />
                                  {/* <ActionButton onClick={(e) => e.stopPropagation()} tooltip="View" Icon={EyeIcon} /> */}
                                </ActionButtonContainer>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );

}