import { Fragment, useRef, useState } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";

import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";

import classNames from "../../utils/classNames";
// import { notificationPolicyTypeEnum } from "../../constants/enums";
import useAgencyBranches from "../../data/useAgencyBranches";
import { withAgency } from "../../data/withAgency";
import LoadingWheel from "../Shared/LoadingWheel";
// import useAgencyUsers from "../../data/useAgencyUsers";
import useBranchDepartments from "../../data/useBranchDepartments";

// function _BranchInput({ agency, value, setValue }) {
//   const { branches } = useAgencyBranches(agency.id);

//   if (branches.isLoading) {
//     return (
//       <div className="flex justify-center">
//         <LoadingWheel width="w-6" height="h-6" />
//       </div>
//     );
//   } else {
//     return (
//       <Listbox
//         value={value}
//         onChange={(v) => setValue({ id: v.id, name: v.name })}
//       >
//         {({ open }) => (
//           <>
//             <Listbox.Label className="block text-sm font-medium text-gray-700">
//               Branch
//             </Listbox.Label>
//             <div className="mt-1 relative">
//               <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
//                 <span className="block truncate">
//                   {value !== ""
//                     ? branches.data.find((b) => b.id === value.id).name
//                     : "Choose..."}
//                 </span>
//                 <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
//                   <SelectorIcon
//                     className="h-5 w-5 text-gray-400"
//                     aria-hidden="true"
//                   />
//                 </span>
//               </Listbox.Button>

//               <Transition
//                 show={open}
//                 appear={true}
//                 as={Fragment}
//                 leave="transition ease-in duration-100"
//                 leaveFrom="opacity-100"
//                 leaveTo="opacity-0"
//               >
//                 <Listbox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
//                   {branches.data.map((b) => (
//                     <Listbox.Option
//                       key={b.id}
//                       className={({ active }) =>
//                         classNames(
//                           active ? "text-white bg-etpink-600" : "text-gray-900",
//                           "cursor-default select-none relative py-2 pl-8 pr-4"
//                         )
//                       }
//                       value={b}
//                     >
//                       <>
//                         <span
//                           className={classNames(
//                             value.id === b.id ? "font-semibold" : "font-normal",
//                             "block truncate"
//                           )}
//                         >
//                           {b.name}
//                         </span>

//                         {value.id === b.id ? (
//                           <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
//                             <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                           </span>
//                         ) : null}
//                       </>
//                     </Listbox.Option>
//                   ))}
//                 </Listbox.Options>
//               </Transition>
//             </div>
//           </>
//         )}
//       </Listbox>
//     );
//   }
// }

// const BranchInput = withAgency(_BranchInput);

// function _UserInput({ agency, value, setValue }) {
//   const { users } = useAgencyUsers(agency.id);

//   if (users.isLoading) {
//     return (
//       <div className="flex justify-center">
//         <LoadingWheel width="w-6" height="h-6" />
//       </div>
//     );
//   } else {
//     return (
//       <Listbox
//         value={value}
//         onChange={(v) =>
//           setValue({ id: v.id, name: `${v.user.firstName} ${v.user.surname}` })
//         }
//       >
//         {({ open }) => (
//           <>
//             <Listbox.Label className="block text-sm font-medium text-gray-700">
//               User
//             </Listbox.Label>
//             <div className="mt-1 relative">
//               <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
//                 <span className="block truncate">
//                   {value !== "" ? value.name : "Choose..."}
//                 </span>
//                 <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
//                   <SelectorIcon
//                     className="h-5 w-5 text-gray-400"
//                     aria-hidden="true"
//                   />
//                 </span>
//               </Listbox.Button>

//               <Transition
//                 show={open}
//                 appear={true}
//                 as={Fragment}
//                 leave="transition ease-in duration-100"
//                 leaveFrom="opacity-100"
//                 leaveTo="opacity-0"
//               >
//                 <Listbox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
//                   {users.data.map((u) => (
//                     <Listbox.Option
//                       key={u.id}
//                       className={({ active }) =>
//                         classNames(
//                           active ? "text-white bg-etpink-600" : "text-gray-900",
//                           "cursor-default select-none relative py-2 pl-8 pr-4"
//                         )
//                       }
//                       value={u}
//                     >
//                       <>
//                         <span
//                           className={classNames(
//                             value.id === u.id ? "font-semibold" : "font-normal",
//                             "block truncate"
//                           )}
//                         >
//                           {u.user.firstName} {u.user.surname}
//                         </span>

//                         {value.id === u.id ? (
//                           <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
//                             <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                           </span>
//                         ) : null}
//                       </>
//                     </Listbox.Option>
//                   ))}
//                 </Listbox.Options>
//               </Transition>
//             </div>
//           </>
//         )}
//       </Listbox>
//     );
//   }
// }

// const UserInput = withAgency(_UserInput);

function DepartmentInput({ branchId, value, setValue }) {
  const { departments } = useBranchDepartments(branchId);

  if (departments.isLoading) {
    return (
      <div className="flex justify-center">
        <LoadingWheel width="w-6" height="h-6" />
      </div>
    );
  } else {
    return (
      <Listbox
        value={value}
        onChange={(v) => setValue({ id: v.id, name: v.name })}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Department
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                <span className="block truncate">
                  {value !== ""
                    ? departments.data.find((d) => d.id === value.id).name
                    : "Choose..."}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-40 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {departments.data.map((d) => (
                    <Listbox.Option
                      key={d.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-etpink-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={d}
                    >
                      <>
                        <span
                          className={classNames(
                            value.id === d.id ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {d.name}
                        </span>

                        {value.id === d.id ? (
                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }
}

// function ChooseDepartment({ value, setValue }) {
//   const [selectedBranch, setSelectedBranch] = useState("");

//   return (
//     <>
//       <BranchInput value={selectedBranch} setValue={setSelectedBranch} />
//       {selectedBranch && (
//         <div className="mt-4">
//           <DepartmentInput
//             branchId={selectedBranch.id}
//             value={value}
//             setValue={setValue}
//           />
//         </div>
//       )}
//     </>
//   );
// }

export default function NotificationRecieverAddModal({ open, setOpen, onAddClick, branchId }) {
  // const types = Object.values(notificationPolicyTypeEnum);
  // const [type, setType] = useState(types[0]);
  const [value, setValue] = useState("");
  const cancelButtonRef = useRef(null);

  // const getInput = () => {
  //   switch (type.toLowerCase()) {
  //     case "all":
  //       return <></>;
  //     case "user":
  //       return <UserInput value={value} setValue={setValue} />;
  //     case "branch":
  //       return <BranchInput value={value} setValue={setValue} />;
  //     case "department":
  //       return <ChooseDepartment value={value} setValue={setValue} />;
  //     default:
  //       return <></>;
  //   }
  // };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-0 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-8 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Add Recipient
                    </Dialog.Title>
                    <div className="my-4 pr-24">
                      <DepartmentInput branchId={branchId} value={value} setValue={setValue} />
                    </div>
                    <div className="mt-10 flex justify-end">
                      <button
                        onClick={() => {
                          setValue("");
                          onAddClick(value);
                        }}
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:w-auto sm:text-sm"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
