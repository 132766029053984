import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, Transition, Listbox } from "@headlessui/react";

import useValuations from "../../data/useValuations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import EmailChips from "../Shared/EmailChips";
import TinyMCE from "../Shared/TinyMCE";

export default function ValuationUpdateModal({ open, setOpen, valuation }) {
  const cancelButtonRef = useRef(null);
  const { updateWithEmail } = useValuations();
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation");
  const { register, getValues, setValue, watch, handleSubmit, reset } =
    useForm({
      defaultValues: {
        saleValue: valuation.saleValue,
        rentalValue: valuation.rentalValue,
        appraisalNotes: valuation.appraisalNotes,
      },
    });
  const [confirm, setConfirm] = useState(false);
  const [additionalEmails, setAdditionalEmails] = useState(false);
  const [error, setError] = useState("");

  const handleConfirm = (e) => {
    e.preventDefault();


    if (valuation.valuationSource !== 1) {
      //Check for appraisal notes
      if (getValues("appraisalNotes") === "" || getValues("appraisalNotes") === null) {
        setError(" - Please enter appraisal notes!");
      } else {
        setConfirm(true);
        setError("");
      }
    } else {
      setConfirm(true);
    }
  }

  const onSubmit = (data) => {
    var appraisalSent = valuation.valuationSource !== 1 ? true : false;

    let valuationUpdate = {
      ...valuation,
      id: valuation.id,
      saleValue: data.saleValue,
      rentalValue: data.rentalValue,
      appraisalNotes: data.appraisalNotes,
      appraisalSent: appraisalSent,
      additionalEmails: additionalEmails.toString()
    };

    if (valuation.valuationSource === 1) {
      valuationUpdate.valuationUpdated = true;
    }

    updateWithEmail.mutate(valuationUpdate, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
        setConfirm(false);
      },
      onError: saveHandlers.onError,
    });

    reset({
      saleValue: data.saleValue,
      rentalValue: data.rentalValue,
      appraisalNotes: data.appraisalNotes,
    });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-5xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {confirm ? (
                        valuation.valuationSource !== 1 ? "Send Appraisal" : "Send Valuation"
                      ) : (
                        valuation.valuationSource !== 1 ? (valuation.appraisalSent ? "Edit Appraisal" : "Add Appraisal") : ("Update Valuation")
                      )}
                    </Dialog.Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      {!confirm ? (
                        <div>
                          <div className="mt-4">
                            <div className="-mx-1 w-full flex items-center">
                              {Boolean(
                                valuation.valuationType === 0 ||
                                valuation.valuationType === 1
                              ) && (
                                  <div className="mx-1 w-1/2">
                                    <label
                                      htmlFor="saleValue"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Sales Valuation
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                        £
                                      </span>
                                      <input
                                        {...register("saleValue")}
                                        type="text"
                                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm border-gray-300"
                                      />
                                    </div>
                                  </div>
                                )}
                              {Boolean(
                                valuation.valuationType === 0 ||
                                valuation.valuationType === 2
                              ) && (
                                  <div className="mx-1 w-1/2">
                                    <label
                                      htmlFor="rentalValue"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Rental Valuation
                                    </label>
                                    <div className="mt-1 flex rounded-md shadow-sm">
                                      <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                        £
                                      </span>
                                      <input
                                        {...register("rentalValue")}
                                        type="text"
                                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm border-gray-300"
                                      />
                                    </div>
                                  </div>
                                )}
                            </div>

                            {valuation.valuationSource !== 1 &&
                              <>
                                <div className="my-4">
                                  <label
                                    htmlFor="appraisalNotes1"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Write your appraisal notes here <span className="text-red-500">{error}</span>
                                  </label>
                                  <div className="mt-1">
                                    <TinyMCE
                                      value={getValues("appraisalNotes")}
                                      onChange={(e) => setValue("appraisalNotes", e)}
                                      toolbar2={false}
                                      height={300}
                                    />
                                  </div>
                                </div>


                              </>
                            }
                          </div>
                          <div className="mt-6 flex justify-end">
                            <button
                              type="button"
                              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:w-auto sm:text-sm"
                              onClick={(e) => handleConfirm(e)}
                            >
                              {valuation.valuationSource !== 1 ? (valuation.appraisalSent ? "Edit Appraisal" : "Add Appraisal") : ("Update Valuation")}

                            </button>

                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setOpen(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-5">
                          <div>
                            {valuation.valuationSource !== 1 ? (
                              <>
                                <p>Are you sure this appraisal is ready to send?</p> <br />
                                <p>This action will send an automated email to the client.</p> <br />
                              </>
                            ) : (
                              <>
                                <p>Are you sure this valuation is ready to be updated?</p> <br />
                                <p>This action will send an automated email to the client.</p> <br />
                              </>
                            )}
                            {valuation.appraisalSent && <p className="font-semibold">An email has already been sent for this valuation.</p>}
                          </div>

                          {valuation.valuationSource !== 1 && (
                            <div className="my-4">
                              <label
                                htmlFor="appraisalNotes2"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Additional Email Addresses
                              </label>
                              <div className="mt-2">
                                <EmailChips additionalEmail={additionalEmails} setAdditionalEmails={setAdditionalEmails} />
                              </div>
                            </div>
                          )}

                          <div className="mt-6 flex justify-end">
                            <button
                              type="submit"
                              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:w-auto sm:text-sm"
                            >
                              {valuation.valuationSource !== 1 ? "Send Appraisal" : "Send Valuation"}
                            </button>
                            <button
                              type="button"
                              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                              onClick={() => setConfirm(false)}
                              ref={cancelButtonRef}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
