import { useEffect } from "react";
import { useState } from "react";

import useAgencyUsers from './../../data/useAgencyUsers';
import Modal from './Modal';
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ButtonInput } from "./Inputs/ButtonInput";

export default function AssignUser({ open, setOpen, lead, update, saveHandler, classes = null }) {
  const { saveHandlers } = useDefaultCRUDHandlers(saveHandler);
  const { users } = useAgencyUsers(lead.agencyId);
  const [user, setUser] = useState(null)

  const handleAssignUser = (selectedUser) => {
    const updatedLead = {
      ...lead,
      userId: selectedUser.id
    };

    updateLead(updatedLead);
    setUser(user);
  }

  const handleUnassignUser = () => {
    const updatedLead = {
      ...lead,
      userId: null
    };

    updateLead(updatedLead);
    setUser(null);
  }

  const updateLead = (updatedLead) => {
    update.mutate(updatedLead, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
      },
      onError: (error) => {
        saveHandlers.onError();
        error.log("error: ", error);
      },
    });
  }

  useEffect(() => {
    if (!users.isLoading) {
      const user = users.data.find(user => user.user.id === lead.userId)
      if (user) {
        setUser(user.user);
      }
    }
  }, [users.isLoading, lead])

  return (
    <>
      <Modal open={open} setOpen={setOpen} width="max-w-3xl" >
        {!users.isLoading &&
          <div>
            <h1 className="text-lg font-semibold">Select a user to assign to this lead</h1>
            <ul className="p-1 mt-2 border-t">
              {users.data.map(user => (
                <li key={user.user.id}
                  onClick={() => handleAssignUser(user.user)}
                  className="cursor-pointer hover:bg-gray-200 p-2 rounded mb-2"
                >
                  <p>{user.user.firstName} {user.user.surname} <span className="text-sm">({user.user.emailAddress})</span> </p>
                </li>
              ))}
            </ul>
          </div>
        }
      </Modal>

      <div className={`whitespace-nowrap ${classes}`}>
        {!user ? (
          <ButtonInput onClick={() => setOpen(true)} isSubmit={false} label="Assign User" classes="!w-full" />
        ) : (
          <ButtonInput onClick={() => handleUnassignUser()} isSubmit={false} label="Unassign User" classes="!w-full" />
        )
        }
      </div>
    </>
  )

}


