import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useReviews(minStar = 4) {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const reviews = useQuery(
    ["reviews", agency.id, minStar],
    () => get(`/Review/${agency.id}?minStar=${minStar ?? 0}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const requests = useQuery(
    ["reviewRequests", agency.id, minStar],
    () => get(`/ReviewRequest/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((review) => post(`/Review/${agency.id}`, review), {
    onSuccess: () => queryClient.invalidateQueries(["reviews", agency.id]),
  });

  const requestReview = useMutation((review) => post(`/Review/${agency.id}/request`, review), {
    onSuccess: () => {
      queryClient.invalidateQueries(["reviewRequests", agency.id]);
      queryClient.invalidateQueries(["emails", agency.id]);
    },
  });

  const resendRequest = useMutation(
    (requestId) => post(`/ReviewRequest/${agency.id}/${requestId}/resend`),
    {
      onSuccess: () => queryClient.invalidateQueries(["reviewRequests", agency.id]),
    }
  );

  const update = useMutation(
    (review) => put(`/Review/${agency.id}/${review.id}`, review),
    {
      onSuccess: () => queryClient.invalidateQueries(["reviews", agency.id]),
    }
  );

  const remove = useMutation(
    (reviewId) => del(`/Review/${agency.id}/${reviewId}`),
    {
      onSuccess: (_, reviewId) => queryClient.invalidateQueries(["reviews", agency.id]),
    }
  );


  const reply = useMutation(
    (data) => post(`/Review/${agency.id}/${data.reviewId}/reply`, data.body),
    {
      onSuccess: () => queryClient.invalidateQueries(["reviews", agency.id]),
    }
  );


  return {
    reviews,
    add,
    requestReview,
    update,
    remove,
    requests,
    resendRequest,
    reply
  };
}
