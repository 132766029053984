import React from "react";
import "./App.css";
import { useRoutes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { NotifierContextProvider } from "react-headless-notifier";

import AccessibleNavigationAnnouncer from "./components/Shared/AccessibleNavigationAnnouncer";
import ErrorBoundary from "./ErrorBoundary";
import { UserContextProvider } from "./contexts/UserContext";
import routes from "./routes";
import GoogleReAuthModal from "./components/GoogleOAuth/GoogleReAuthModal";

const queryClient = new QueryClient();

function App() {
  const routing = useRoutes(routes);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <AccessibleNavigationAnnouncer />
        <NotifierContextProvider
          config={{
            max: 3,
            position: "topRight",
          }}
        >
          <UserContextProvider>
            <GoogleReAuthModal />
            {routing}
          </UserContextProvider>
        </NotifierContextProvider>
      </ErrorBoundary>

      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
